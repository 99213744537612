body{
    margin: 0
}

.custom-navlink {
    text-decoration: none;
    color: #0073ae
}
.custom-navlink:hover {
    text-decoration: underline;
}

.custom-otpLink {
    text-decoration: none;
}